import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { NextPageContext } from 'next';
import { makeHostSum } from '@/utils/helpers';
import { apolloClient } from '@/api/apolloClient';
import {
  GetSeoResponse,
  SeoFullData,
  SeoMetadata,
} from '@/api/types/seo.types';
import { GET_SEO } from '@/api/queries/seo.queries';
import {
  CATEGORIES_SLUGS_COUNT,
  ROOT_CATEGORY_SLUG,
  SINGLE_AD_PAGE_SLUGS_COUNT,
} from '@/constants/common';
import { parseQuery } from '@/hooks/useParseQuery/useParseQuery.helpers';
import { GET_REGIONS } from '@/api/queries/regions.queries';
import { GetRegionsResponse } from '@/api/types/regions.types';

async function getSeoMetaData(
  client: ApolloClient<NormalizedCacheObject>,
  context: NextPageContext,
  asPath: string
): Promise<SeoMetadata> {
  const path = asPath || '/';
  const seoData = await apolloClient<GetSeoResponse>(
    client,
    { query: GET_SEO, variables: { path } },
    context
  );

  return {
    title: seoData?.data.seoMetadata.title || null,
    ogTitle: seoData?.data.seoMetadata.title || null,
    description: seoData?.data.seoMetadata.description || null,
    jsonLd: seoData?.data.seoMetadata.jsonLd || null,
  };
}

async function getSeoMetaDataForSingleAd(
  client: ApolloClient<NormalizedCacheObject>,
  context: NextPageContext,
  adLegacyId: number,
  path: string
): Promise<SeoMetadata> {
  const seoData = await apolloClient<GetSeoResponse>(
    client,
    { query: GET_SEO, variables: { adLegacyId, path } },
    context
  );

  return {
    title: seoData?.data.seoMetadata.title || null,
    ogTitle: seoData?.data.seoMetadata.adTitle || null,
    description: seoData?.data.seoMetadata.description || null,
    jsonLd: seoData?.data.seoMetadata.jsonLd || null,
  };
}

async function getSeoMetaDataForSearchResults(
  client: ApolloClient<NormalizedCacheObject>,
  context: NextPageContext,
  asPath: string
): Promise<SeoMetadata> {
  const { query } = context;
  const path = asPath.split('/').slice(0, CATEGORIES_SLUGS_COUNT).join('/');
  const isRootCategory = path === ROOT_CATEGORY_SLUG;

  const regions = await apolloClient<GetRegionsResponse>(
    client,
    { query: GET_REGIONS },
    context
  );

  const {
    keywords,
    price,
    regionId,
    propertyOptions,
    userLegacyId,
    categoryId,
  } = parseQuery(query, regions?.data);

  const filters = {
    price,
    regionId,
    propertyOptions,
    userLegacyId,
    categoryId: isRootCategory ? null : categoryId,
  };

  const seoData = await apolloClient<GetSeoResponse>(
    client,
    {
      query: GET_SEO,
      variables: { path, keywords, filters },
      fetchPolicy: 'no-cache',
    },
    context
  );

  const title = seoData?.data.seoMetadata.title || null;

  return {
    title,
    ogTitle: !isRootCategory ? title : '',
    description: seoData?.data.seoMetadata.description || null,
    jsonLd: seoData?.data.seoMetadata.jsonLd || null,
  };
}

export async function getSeoFullData(
  client: ApolloClient<NormalizedCacheObject>,
  context: NextPageContext
): Promise<SeoFullData> {
  const hostSum = makeHostSum({
    asPath: context.asPath ?? context.req?.url,
    req: context.req,
  });

  const asPath = context.asPath?.split('?')[0].slice(1) || '';
  const slugs = asPath.split('/');

  const isSingleAdPage =
    slugs.length === SINGLE_AD_PAGE_SLUGS_COUNT &&
    /^[0-9]+$/.test(slugs[slugs.length - 1]);
  const adLegacyId = Number(slugs[SINGLE_AD_PAGE_SLUGS_COUNT - 1]);

  if (isSingleAdPage && adLegacyId) {
    const seoMetaData = await getSeoMetaDataForSingleAd(
      client,
      context,
      adLegacyId,
      asPath
    );

    return { ...seoMetaData, hostSum };
  }

  if (asPath?.includes(ROOT_CATEGORY_SLUG)) {
    const seoMetaData = await getSeoMetaDataForSearchResults(
      client,
      context,
      asPath
    );

    return { ...seoMetaData, hostSum };
  }

  const { jsonLd } = await getSeoMetaData(client, context, asPath);

  return { title: '', ogTitle: '', description: '', jsonLd, hostSum };
}
