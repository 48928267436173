import { TFunction } from 'i18next';
import { ROUTES } from '@/constants/routes';
import { getUrlBasedOnAuth, getNewAdPageHref } from '@/utils/helpers';

export const tabBarLinks = (
  t: TFunction,
  isAuthenticated: boolean,
  currentUrl: string
) => {
  return [
    {
      href: ROUTES.home,
      iconName: 'home',
      isBig: false,
      text: t('common.main'),
    },
    {
      href: ROUTES.bookmarks,
      iconName: 'favorites',
      isBig: false,
      text: t('common.favorites'),
      isDisabled: currentUrl === ROUTES.bookmarks,
    },
    {
      href: getNewAdPageHref(currentUrl),
      iconName: 'add',
      isBig: true,
      text: t('common.add'),
    },
    {
      href: getUrlBasedOnAuth(isAuthenticated, ROUTES.messages, currentUrl),
      iconName: 'messages',
      isBig: false,
      text: t('common.messages'),
    },
    {
      href: getUrlBasedOnAuth(isAuthenticated, ROUTES.profile, currentUrl),
      iconName: 'cabinet',
      isBig: false,
      text: t('common.cabinet'),
    },
  ];
};
