import React from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { CONFIG } from '@/constants/config';
import {
  addLangToUrl,
  cleanupSeoUrl,
  removeLangFromUrl,
} from '@/utils/helpers';
import { ROUTES } from '@/constants/routes';
import { JsonLd, JsonLdProduct } from '@/api/types/seo.types';

interface Props {
  currentUrl: string;
  jsonLd?: JsonLd | null;
}

export const HeadMeta = ({ currentUrl, jsonLd }: Props) => {
  const router = useRouter();
  const isHomePage = router.pathname === ROUTES.home;
  const productInfo = jsonLd?.find<JsonLdProduct>(
    (item): item is JsonLdProduct => item['@type'] === 'Product'
  );
  return (
    <Head>
      <meta
        key="viewport"
        content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0, viewport-fit=cover"
        name="viewport"
      />
      <meta name="google" content="notranslate" />
      <meta
        key="format-detection"
        content="telephone=no"
        name="format-detection"
      />
      <meta key="theme-color" content="#fff" name="theme-color" />

      <link
        rel="apple-touch-icon"
        sizes="192x192"
        href="/images/favicons/favicon-192x192.png"
      />

      <link
        rel="icon"
        type="image/png"
        sizes="192x192"
        href="/images/favicons/favicon-192x192.png"
      />

      <meta name="msapplication-TileColor" content="#fff" />
      <meta
        name="msapplication-TileImage"
        content="/images/favicons/ms-icon-144x144.png"
      />
      <meta name="theme-color" content="#fff" />

      <meta
        key="msapplication-config"
        content="/browserconfig.xml"
        name="msapplication-config"
      />

      <link href="/manifest.webmanifest" rel="manifest" />

      {isHomePage && (
        <meta name="apple-itunes-app" content="app-id=1132083305" />
      )}

      {CONFIG.assetHost && (
        <link href={`//${CONFIG.assetHost}`} rel="dns-prefetch" />
      )}

      <link href={cleanupSeoUrl(currentUrl)} rel="canonical" />

      {CONFIG.languages.map(lang => (
        <link
          key={lang}
          hrefLang={lang}
          rel="alternate"
          href={cleanupSeoUrl(
            addLangToUrl(removeLangFromUrl(currentUrl), lang)
          )}
        />
      ))}

      <meta name="googlebot" content="index,follow" />

      {jsonLd && (
        <script type="application/ld+json">{JSON.stringify(jsonLd)}</script>
      )}

      {productInfo && (
        <>
          <meta property="og:type" content="product" />
          {productInfo.image && (
            <meta property="og:image" content={productInfo?.image} />
          )}
          {productInfo.offers?.price && (
            <meta
              property="product:price:amount"
              content={productInfo.offers.price}
            />
          )}
          {productInfo.offers?.priceCurrency && (
            <meta
              property="product:price:currency"
              content={productInfo.offers.priceCurrency}
            />
          )}
          {productInfo.offers?.availabilityStarts && (
            <meta
              property="og:updated_time"
              content={productInfo.offers?.availabilityStarts}
            />
          )}
        </>
      )}
    </Head>
  );
};
