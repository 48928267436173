import {
  ApolloClient,
  NormalizedCacheObject,
  QueryOptions,
} from '@apollo/client';
import { OperationVariables } from '@apollo/client/core/types';
import { ErrorResponse } from '@apollo/client/link/error';
import { NextPageContext } from 'next';
import { logError } from '@/utils/log-error';

export async function apolloClient<TData>(
  client: ApolloClient<NormalizedCacheObject>,
  options: QueryOptions<OperationVariables, TData>,
  context: NextPageContext
) {
  try {
    return await client.query<TData>(options);
  } catch (e) {
    const { graphQLErrors, networkError } = e as ErrorResponse;

    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path }) => {
        logError(
          `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(
            locations
          )}, Path: ${path}`,
          context.req?.headers.host
        );
      });
    }

    if (networkError) {
      logError(`[Network error]: ${networkError}`, context.req?.headers.host);
    }
  }
}
