import { gql } from '@apollo/client';

export const GET_SEO = gql`
  query seoMetadata(
    $path: String
    $adLegacyId: ID
    $keywords: String
    $filters: AdFilterInput
  ) {
    seoMetadata(
      path: $path
      adLegacyId: $adLegacyId
      keywords: $keywords
      filters: $filters
    ) {
      title
      adTitle
      description
      jsonLd
    }
  }
`;
